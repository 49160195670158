window.ymapVue = new Vue({
    el: '#ymap',
    data: {
        map: undefined,
        points: [],
        yaMapsShown: false,
    },
    methods: {
        initMap: function () {
            this.map = new ymaps.Map("ymap", {
                center: [55.76, 37.64],
                zoom: 9,
                controls: []
            }, {
                searchControlProvider: 'yandex#search',
                suppressMapOpenBlock: true,
                suppressObsoleteBrowserNotifier: true,
            });

            this.map.controls.add(new ymaps.control.FullscreenControl());
            this.map.controls.add(new ymaps.control.ZoomControl({
                options: {
                    size: "small"
                }
            }));
            if (this.points !== null) {
                this.points.map(point => {
                    this.map.geoObjects.add(new ymaps.Placemark([point.lat, point.lng], {
                        balloonContent: point.title + '<br>' +
                            '<div class="b-btn-base base-br">' +
                            '<button style="margin-top: 34px; line-height: 25px" ' +
                            'onclick="window.open(\'https://yandex.ru/maps/?text=' + point.lat + ',' + point.lng + '&rtt=mt\',\'_blank\') " ' +
                            'rel="nofollow noindex noreferrer" class="btn text-center map-btn fs-3">Перейти' +
                            '</button>' +
                            '</div>'
                    }, {
                        iconLayout: 'default#imageWithContent',
                        iconImageHref: '/assets/images/icons/marker2.png',
                        iconImageSize: [20, 20],
                        iconImageOffset: [-24, -24],
                        iconContentOffset: [15, 15],
                }));
                })
            }
        },
        getPoints: function () {
            axios.get('/api/get-map-points').then(response => {
                this.points = response.data;
                this.initMap();
            }).catch(e => {
                console.log(e);
            });
        },
        mountMap: function () {
            let el = $('#ymap')[0];
            var rect = el.getBoundingClientRect();

            if (rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */) {
                var elem = document.createElement('script');
                elem.type = 'text/javascript';
                elem.src = 'https://api-maps.yandex.ru/2.1/?load=package.standard,package.geoObjects&lang=ru_RU&amp;apikey=8c1ddd83-7c9c-4d9e-a532-9c67ea915ff8&onload=window.ymapVue.getPoints';
                document.getElementsByTagName('body')[0].appendChild(elem);
                this.yaMapsShown = true;
            }
        }
    },
    async mounted() {
        if ($('#ymap').is(":visible")) {
            this.mountMap();
            $(window).scroll(function () {
                if (!ymapVue.yaMapsShown) {
                    ymapVue.mountMap();
                }
            });
        }
    }
});
